<template>
    <div class="main-contents code">
        <div class="tit">상세코드 관리</div>
        <div class="search-box interval">
            <SelectComp list="a:업무코드,b:업무코드명,c:상세코드,d:상세코드명" v-model="srchFilter.indtypeClassCd" title="업무코드" @change="srchTxtPlaceholder = $event.target.selectedOptions[0].label" />
            <!-- <input type="text" placeholder="업무코드">  -->
            <InputComp v-model="srchFilter.srchTxt" :placeholder="srchTxtPlaceholder" @keyup.enter="getDetailCodeList('1')" /> &nbsp;
            <SelectComp class="wd01" list="Y:예,N:아니오" v-model="srchFilter.apprDiv" :isAll="true" title="사용여부"/>&nbsp;
            <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getDetailCodeList('1')"/>
            <div class="sub_btn" @click="$router.push('MAN931M04')">
                +등록
            </div>
        </div>
        <div class="Board type3">
            <table class="Board_type3 admin">
                <colgroup>
                    <col width="4%">
                    <col width="17%">
                    <col width="17%">
                    <col width="6%">
                    <col width="9%">
                    <col width="36%">
                    <col width="11%">
                </colgroup> 
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>업무코드</th>
                        <th>업무코드명</th>
                        <th>정렬순서</th>
                        <th>상세코드</th>
                        <th>상세코드명</th>
                        <th>사용여부</th>
                    </tr>
                </thead>
                <tbody v-if="detailCodeList.length > 0">            
                <!-- 최대 25건 조회 -->
                    <tr v-for="(row, rowIdx) in detailCodeList" :key="rowIdx">
                        <td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (rowIdx+1)}}</td>
                        <td class="score">{{row.cdId}}</td>
                        <td class="score">{{row.cdIdNm}}</td>
                        <td class="score">{{row.sortOrd}}</td>
                        <td class="score">{{row.cd}}</td>
                        <td class="score btn_cursor" @click="viewBusinessDetail(row)">{{row.cdNm}} &#9654;</td>
                        <td class="score" v-if="row.useYn == 'Y'">예</td>
                        <td class="score" v-else>아니오</td>
					</tr>
                </tbody>
                <tbody v-else>            
                <!-- 관련내용 없을 경우 -->
                    <tr>
                        <td colspan="7" class="none">등록된 상세코드가 없습니다!</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 페이징부분 -->
		<pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
    </div>
</template>

<script>

import pagingComp from '@/components/PagingComp.vue';
import SelectComp from "@/components/base/SelectComp.vue";
export default {
    data() {
        return {
            // 검색 조건 
            srchFilter : {
				indtypeClassCd : 'a',
				srchTxt : '',
                apprDiv : '',
				pageIndex : 1,
			},

            // 코드 목록
            detailCodeList : [],
            
            // 페이징
			pageInfo : {},

            // placeholder
            srchTxtPlaceholder : '업무코드',
        }
    },
    components: { 
        SelectComp,
        pagingComp
    },

    mounted() {

		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}

		this.getDetailCodeList()
	},
    
    methods: {
        
        // 업무코드 목록 조회
        getDetailCodeList(div){
            // 기본테스크가 비워져서 나옴 
            
            var param = this.srchFilter;
            // console.log(11111111111111111111111111111111);
            // console.log('param.indtypeClassCd', param.indtypeClassCd);
            param.pageUnit = '25';
            param.pageSize = '10';
            if(div) param.pageIndex = 1;

            // console.log('getDetailCodeList PARAM', param);

			this.$.httpPost('/api/main/adm/code/getDetailCodeList', param)
				.then(res => {
					// console.log('getDetailCodeList RESULT', res);

					this.detailCodeList = res.data.detailCodeList;
					this.pageInfo = res.data.pageInfo;
				}).catch(this.$.httpErrorCommon);
        },

        // 페이징 이동
		goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getDetailCodeList();
		},

        viewBusinessDetail(deta) {
            var params = {
				detaSeq : deta.cdId,
                detaSubSeq : deta.cd,
				caller : {
					name: this.$route.name,
					params: this.srchFilter
				}
			}
            // console.log(this.$router);
			this.$router.push({name: 'MAN931M04', params });
        },
    },

}
</script>